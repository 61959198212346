<template>
  <div class="manageBox">
    <div class="content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="流程名称" prop="name">
          <el-input style="width: 300px" clearable maxlength="20" show-word-limit v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="服务对象类别" prop="serviceObjectIds">
          <el-select style="width: 300px" multiple v-model="ruleForm.serviceObjectIds" placeholder="请选择">
            <el-option :label="item.name" :value="item.id" v-for="item in configOpt" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批流程" prop="process">
          <el-timeline>
            <el-timeline-item v-for="(item, index) in ruleForm.process" :key="index">
              <div v-if="item.isMute === '1'" slot="dot" class="dot">
                <img src="../../../assets/imgs/processCheck.png" width="20" height="20" alt="">
              </div>
              <div v-else slot="dot" class="dot1">
                <img src="../../../assets/imgs/processClose.png" width="20" height="20" alt="">
              </div>
              <div class="process-box">
                <div :class="index === 0 ? 'top-title-bcyellow' : item.isMute === '1' ? 'top-title-bcgreen' : 'top-inactive'">
                  <div>{{index === 0 ? '提交人' : '审批人'}}</div>
                  <img v-if="item.isMute === '1' && item.isShowClose" @click="delItem(index)" src="../../../assets/imgs/proClose.png" width="16px" height="16px" alt="">
                  <span v-if="item.isMute === '0' && item.isShowClose" @click="delItem(index)" alt=""><i class="el-icon-error"></i></span>
                </div>
                <div class="top-content" @click="selectPerson(item, index)">
                  <span>{{item.name || ( index === 0 ? '请选择提交人': '请选择审批人')}}</span>
                  <span> > </span>
                </div>
              </div>
            </el-timeline-item>
            <img src="../../../assets/imgs/puls.png" @click="handlerToPlus" style="cursor: pointer" width="24px" height="24px" class="plus" alt="">
          </el-timeline>
        </el-form-item>
      </el-form>
      <!--横线-->
      <div style="border-bottom: 1px solid #ccc;padding-top: 30px"></div>
      <!--表格-->
      <div class="table-box">
        <div class="topInfo">
          <span class="topTitleStyle">审批附件类型</span>
          <el-button type="primary" @click="addOrEditFiles">新增</el-button>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          :row-style="{ height: '55px' }"
          style="width: 100%"
          :header-cell-style="headerCellStyle"
          class="content-itme"
        >
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column prop="name" align="center" label="附件名称"/>
          <el-table-column prop="attachmentDescribe" align="center" label="附件描述"/>
          <el-table-column prop="phone" align="center" label="是否必填">
            <template slot-scope="scope">
              <span>{{['是', '否'][scope.row.isRequired]}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" class="operation" @click="addOrEditFiles(scope.row)">编辑</el-button>
              <el-button type="text" class="operation stop" @click="delFile(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="btns">
          <el-button  type="primary" plain @click="$router.go(-1)">取 消</el-button>
          <el-button  type="primary" @click="submitForm('ruleForm')">保 存</el-button>
        </div>
      </div>
    </div>
    <selectSubmitPerson @postSelectData="postSelectData" :submitPersonId="submitPersonItem.id" v-if="selectSubmitPersonVisible" :selectSubmitPersonVisible.sync="selectSubmitPersonVisible"/>
    <!--是否需要社区居委会审核-->
    <isAudit v-if="isAuditVisible" :isMute="inactiveAuditItem.isMute" :isAuditVisible.sync="isAuditVisible" @postAuditMute="postAuditMute"/>
    <!--是否需要街道办事处审核-->
    <isNeedStreeAudit v-if="isNeedStreeAuditVisible" :isMute="inactiveStreeItem.isMute" :isNeedStreeAuditVisible.sync="isNeedStreeAuditVisible" @postStreeAuditMute="postStreeAuditMute"/>
    <!--新增审批人-->
    <otherAuditPerson @postOtherAudit="postOtherAudit" :selectedArr="getOtherIds()" :otherId="otherAuditPersonItem.id" :otherAuditPersonVisible.sync="otherAuditPersonVisible"/>
    <!--新增附件-->
    <addFiles v-if="fileVisible" :rowItem="rowItem" @updateList="selectEnclosureData" :fileVisible.sync="fileVisible"/>
  </div>
</template>

<script>
  import selectSubmitPerson from "./components/selectSubmitPerson";
  import isNeedStreeAudit from "./components/isNeedStreeAudit";
  import isAudit from "./components/isAudit";
  import otherAuditPerson from "./components/otherAuditPerson";
  import addFiles from "./components/addFiles";
  import { deleteEnclosureSome,updateSubsidyProcess, getselectServiceObject, selectEnclosure, deleteEnclosure, selectById } from "@/api/homeCasedCareServices"
  export default {
    components: {
      selectSubmitPerson,
      isAudit,
      isNeedStreeAudit,
      otherAuditPerson,
      addFiles
    },
    name: "addOrEditSubsidyApprovalProcess",
    data() {
      return {
        rowItem: {},
        configOpt: [],
        loading: false,
        tableData: [],
        fileVisible: false,
        otherAuditPersonVisible:false,
        isNeedStreeAuditVisible: false,
        isAuditVisible: false,
        selectSubmitPersonVisible: false,
        ruleForm: {
          process: [
            {
              id: "2",
              name: '街道办事处',
              isMute: "1",
              type: 0,
              isShowClose: false
            },
            {
              id: "1",
              name: '社区居委会',
              isMute: "0",
              type: 0,
              isShowClose: false
            },
            {
              id: "2",
              name: '街道办事处',
              isMute: "1",
              type: 0,
              isShowClose: false
            },
          ],
          name: '',
          serviceObjectIds: [], //类别Id
          serviceObjectName: [] //类别名字
        },
        rules: {
          name: [
            { required: true, message: '请输入流程名称', trigger: 'blur' },
            { max: 20, message: '流程名称最长20个字符', trigger: 'blur' }
          ],
          serviceObjectIds: [
            { required: true, message: '请选择服务对象类别', trigger: 'change' },
          ],
          process: [
            { required: true, message: '请选择完善审批流程', trigger: 'change' },
          ]
        },
        inactiveAuditItem: {},
        inactiveStreeItem: {},
        submitPersonItem: {},
        otherAuditPersonItem: {},
        rowId: '',
        isNeedValid: false
      }
    },
    watch: {
      'ruleForm.process': {
        deep: true,
        handler(val) {
          let isNeedValid = val.slice(1).every(item => {
            return item.isMute == 0
          })
          this.isNeedValid = isNeedValid
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      to.meta.title =  '编辑'
      next()
    },
    mounted() {
      this.rowId = this.$route.query.id

      this.getselectServiceObjectData() //获取服务对象类别
      // this.selectEnclosureData() //获取附件列表
      this.selectByIdData()
    },
    beforeDestroy() {
      deleteEnclosureSome().then(res => {
        console.log(res)
      })
    },
    methods: {
      /*获取详情数据*/
      async selectByIdData() {
        const res = await selectById({id:this.rowId})
        let { list, name , process, serviceObjectName, serviceObjectIds } = res.data
        this.$nextTick(() => {
          if(serviceObjectIds && serviceObjectName) {
            let names = serviceObjectName.split(',')
            let ids = serviceObjectIds.split(',')
            ids.forEach((item, index) => {
              this.configOpt.unshift({ name: names[index] , id:item  })
            })
          }
        })
        console.log(this.configOpt,'????')
        this.ruleForm.process = JSON.parse(process)
        this.tableData = list
        this.ruleForm.name = name
        this.ruleForm.serviceObjectIds = serviceObjectIds.split(',')
        this.ruleForm.serviceObjectName = serviceObjectName.split(',')
      },
      /*删除附件*/
      delFile(row) {
        this.$confirm(`此操作将删除"${row.name}"附件, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteEnclosure({id: row.id}).then(res => {
            if(res.state === 0) {
              this.$message.success(res.data)
              this.selectEnclosureData() //获取附件列表
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      /*获取附件列表*/
      async selectEnclosureData() {
        const res = await selectEnclosure({id: this.rowId})
        this.tableData = res.data
/*        if(!res.data.length) {
          this.selectByIdData()
        }else {
          this.tableData.forEach((item, index) => {
            console.log(item.id === res.data[0].id, '><<<<<<<<<<<<')
            if(item.id !== res.data[0].id) {
              this.tableData.push(res.data[0])
            }else {
              this.tableData.splice(index, 1, res.data[0])
            }
          })
        }*/
     /*   if(res.state === 0) {
          res.data.forEach(item => {
            this.tableData.push(item)
          })

        }*/
      },
      /*获取类别*/
      async getselectServiceObjectData() {
        const res = await getselectServiceObject()
        this.configOpt = res.data
      },
      /*上传或者新增附件*/
      addOrEditFiles(row) {
        if(row && row.id) {
          this.rowItem = row
        }else {
          this.rowItem = {
            "attachmentDescribe": "",
            "isRequired": 0,
            "name": ""
          }
        }
        this.fileVisible = true
      },
      /*获取新增审批人id*/
      getOtherIds() {
        let arrids = []
        let arr = JSON.parse(JSON.stringify(this.ruleForm.process)).slice(3)
        arr.forEach(item => {
          if(item && item.id) arrids.push(item.id)
        })
        return arrids
      },
      /*新增审批人回传数据*/
      postOtherAudit(data) {
        this.otherAuditPersonItem.id = data.id
        this.otherAuditPersonItem.name = data.name
        this.otherAuditPersonItem.isMute = '1'
      },
      /*选择提交人回传的数据*/
      postSelectData(data) {
        this.submitPersonItem.id = data.id
        this.submitPersonItem.name = data.name
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.isNeedValid) return this.$message.warning('请选择审批人')
            let enclosureIds = []
            let enclosureName = []
            let serviceObjectName = []
            let params = JSON.parse(JSON.stringify(this.ruleForm))
            this.configOpt.forEach(item => {
              if(params.serviceObjectIds.includes(item.id)) {
                serviceObjectName.push(item.name)
              }
            })
            this.tableData.forEach(item => {
              enclosureIds.push(item.id)
              enclosureName.push(item.name)
            })
            params.enclosureName = enclosureName.join(',')
            params.enclosureIds = enclosureIds.join(',')
            params.serviceObjectIds = params.serviceObjectIds.join(',')
            params.serviceObjectName = serviceObjectName.join(',')
            params.process = JSON.stringify(params.process)
            params.id = this.rowId
            if(!this.tableData.length) return this.$message.warning('请填写审批附件类型')
            updateSubsidyProcess(params).then(res => {
              if(res.state === 0) {
                this.$message.success(res.data)
                this.$router.go(-1)
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      /*社区居委会回传数据*/
      postAuditMute(data) {
        this.inactiveAuditItem.isMute = data
      },
      /*街道回传数据*/
      postStreeAuditMute(data) {
        this.inactiveStreeItem.isMute = data
      },
      /*选择人*/
      selectPerson(item, index) {
        switch (index) {
          case 0:
            this.selectSubmitPersonVisible = true
            this.submitPersonItem = item
            break
          case 1:
            this.isAuditVisible = true //社区居委会
            this.inactiveAuditItem = item
            break
          case 2:
            this.isNeedStreeAuditVisible = true //街道办事处
            this.inactiveStreeItem = item
            break
          default:
            this.otherAuditPersonVisible = true
            this.otherAuditPersonItem = item
            break

        }
      },
      /*新增一个*/
      handlerToPlus() {
        if(this.ruleForm.process.length === 6) return this.$message.warning('最多添加5级审批节点')
        this.ruleForm.process.push({id: '', isShowClose: true, isMute: "0", type: "1"})
      },
      /*删除一个*/
      delItem(index) {
        this.ruleForm.process.splice(index, 1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .stop {
    color: #f57473;
  }
  .manageBox {
    background: #fff;
    min-height: calc(100vh - 130px);
    .content {
      padding: 20px;
      .table-box {
        width: 50%;
        .topInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 30px 0;
          .topTitleStyle {
            position: relative;
          }
          .topTitleStyle:before {
            content: '*';
            color: red;
            position: absolute;
            left: -10px;
            top: 0;
          }
        }
        .btns {
          display: flex;
          justify-content: center;
          margin-top: 30px;
        }
        .btns>button:first-child {
          margin-right: 40px;
        }
      }
      /deep/ .el-timeline-item__tail {
        left: 9px;
        border-left: 1px solid #3FCF89;
      }
      /deep/ .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
        display: block;
      }
      .plus {
        position: absolute;
        left: 39px;
      }
      .dot {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        /*border: 1px solid #3FCF89;*/
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: #3FCF89;
        font-size: 14px;
      }
      .dot1 {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        /*border: 1px solid #D0D0D0;*/
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: #3FCF89;
        font-size: 14px;
      }
      .process-box {
        width: 200px;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        margin-left: 30px;
        .top-title-bcgreen {
          background-color: #3FCF89;
          height: 25px;
          display: flex;
          align-items: center;
          position: relative;
          div {
            flex: 1;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
          }
          img {
            cursor: pointer;
            position: absolute;
            right: 10px;
          }
        }
        .top-title-bcyellow {
          height: 25px;
          background-color: #F7C261;
          line-height: 25px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
        }
        .top-inactive {
          background-color: #cccccc;
          height: 25px;
          display: flex;
          align-items: center;
          position: relative;
          div {
            flex: 1;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
          }
          span {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            right: 10px;
            i {
              font-size: 16px;
              color: #ffffff;
            }
          }
        }
        .top-content {
          height: 50px;
          color: #333333;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
</style>
